import { useTranslation } from 'react-i18next';
import dhl from '../../../../../images/carriers/dhl.png';
import txt from '../../../../../images/carriers/txt.png';
import mrw from '../../../../../images/carriers/mrw.png';
import seur from '../../../../../images/carriers/seur.png';

import './totalPedido.scss';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import CodigoDescuento from './components/CodigoDescuento';
import { checkPresupuestoEcommerce } from '../../../../../utils';
import React from 'react';

const TotalPedido = ({ className = '', handleSelectDirEnvio }) => {
    const { t } = useTranslation();
    const carriers = {
        12: seur,
        17: mrw,
        19: txt,
        22: dhl,
    };
    const checkoutInfo = useSelector((state) => {
        return state.checkout.entity;
    });

    const getSubTotal = () => {
        return parseFloat(checkoutInfo.presupuesto.subtotal).toFixed(2);
    };

    const getIVA = () => {
        const IVAvalue =
            checkoutInfo.presupuesto?.iva !== 0
                ? getSubTotal() * (1 + checkoutInfo.presupuesto.iva / 100)
                : 0;
        const IVA =
            checkoutInfo.presupuesto?.iva !== 0
                ? IVAvalue - getSubTotal()
                : IVAvalue;
        return parseFloat(IVA).toFixed(2);
    };

    const getTotal = (conDescuento) => {
        if (conDescuento) {
            const totalIVA = checkoutInfo.presupuesto.total_iva;
            return parseFloat(totalIVA).toFixed(2);
        } else {
            const totalIVA =
                checkoutInfo.presupuesto.total_iva +
                checkoutInfo.presupuesto.total_descuento_iva;
            return parseFloat(totalIVA).toFixed(2);
        }
    };

    return (
        <div className={`total-pedido ${className}`}>
            <h2>{t('carrito.pedido.title')}</h2>
            <p>
                <span>{t('carrito.pedido.subtotal')}</span>
                <span>
                    {(
                        getSubTotal() -
                        checkoutInfo.presupuesto.total_envio +
                        checkoutInfo.presupuesto.total_descuento
                    ).toFixed(2)}
                    €
                </span>
            </p>
            <p>
                <span>
                    {t('carrito.pedido.transporte')}{' '}
                    {checkoutInfo?.presupuesto?.direccion_envio_id &&
                        Object.keys(
                            checkoutInfo.presupuesto.servicios_envio.reduce(
                                (acc, obj) => {
                                    acc[obj.transportista_id] =
                                        (acc[obj.transportista_id] || 0) + 1;
                                    return acc;
                                },
                                {}
                            )
                        ).map((shipping, idx) =>
                            shipping !== '1' ? (
                                <React.Fragment key={shipping}>
                                    {idx !== 0 && '/'}
                                    <img
                                        className="total-pedido__carrier"
                                        key={shipping}
                                        alt={shipping}
                                        src={carriers[shipping]}
                                    />
                                </React.Fragment>
                            ) : (
                                ''
                            )
                        )}
                </span>
                {!checkoutInfo?.presupuesto?.direccion_envio_id &&
                checkPresupuestoEcommerce(
                    checkoutInfo?.presupuesto?.created_by?.username
                ) ? (
                    <span
                        className="total-pedido__no-transporte"
                        onClick={handleSelectDirEnvio}
                    >
                        {t('carrito.pedido.no-transporte')}
                    </span>
                ) : (
                    <span>
                        {checkoutInfo.presupuesto.total_envio.toFixed(2)}€
                    </span>
                )}
            </p>
            {checkoutInfo.presupuesto?.vale_descuento && (
                <p className="pb-2">
                    <span>
                        {t('carrito.codigo-descuento.descuento')}{' '}
                        <span className="codigo-descuento__aplicado">
                            {checkoutInfo.presupuesto?.vale_descuento?.codigo}
                        </span>
                    </span>
                    <span>
                        - {checkoutInfo.presupuesto.total_descuento}€
                    </span>
                </p>
            )}
            <p className="total-pedido__impuestos">
                <span>{t('carrito.pedido.impuestos')}</span>
                {checkoutInfo?.presupuesto?.direccion_envio_id ? (
                    <span>{getIVA()}€</span>
                ) : (
                    <span
                        className="total-pedido__no-transporte"
                        onClick={handleSelectDirEnvio}
                    >
                        {t('carrito.pedido.no-transporte')}
                    </span>
                )}
            </p>

            <CodigoDescuento checkoutInfo={checkoutInfo} />

            <h4>
                <span>{t('carrito.pedido.total')}</span>
                <span>
                    {checkoutInfo?.presupuesto?.direccion_envio_id
                        ? getTotal(
                              checkoutInfo.presupuesto?.vale_descuento_id
                          ) + '€'
                        : t('carrito.pedido.no-transporte')}
                </span>
            </h4>
        </div>
    );
};

TotalPedido.propTypes = {
    className: PropTypes.string,
};

export default TotalPedido;
